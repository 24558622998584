.modal__content {
	.main-text {
		font-style: normal;
		font-weight: 400;
		font-size: 1rem;
		line-height: 150%;
		margin-bottom: 24px;
		color: $gray;
	}
	.confirmation {
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 1rem;
		line-height: 150%;
		margin-bottom: 32px;
		color: $gray;
	}
	&__small {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
}
.payment-success {
	color: $black;
	text-align: center;
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin-bottom: 1.5rem;
}
.payment-success_txt {
	color: $gray;
	font-size: 1rem;
	font-style: normal;
	line-height: 150%;
	span {
		color: #005397;
		font-weight: 500;
	}
}
.confirm-deets-modal {
	// position: relative;

	&__title {
		font-weight: 500;
		line-height: 20px;
		text-align: center;
		color: $gray;

		span {
			text-transform: capitalize;
		}
	}

	&__price-banner {
		position: relative;
		min-width: 339px;
		width: fit-content;
		padding: 1.5rem 1.25rem;
		background-color: $primary-light-1;
		text-align: center;
		border-radius: 4px;

		@media #{ $smalldown } {
			min-width: unset;
			width: 100%;
		}

		.__top--value {
			position: relative;
			font-size: 2rem;
			font-weight: 600;
			line-height: 39px;
			margin-top: 0.625rem;

			@media #{ $smalldown } {
				font-size: 1.75rem;
				line-height: 30px;
			}

			.__period {
				font-size: 14px;
				font-weight: 500;
				line-height: 17px;
				color: $gray;
			}
		}

		.__top--worth {
			padding: 0.5rem 1rem;
			margin-top: 0.5rem;
			font-weight: 500;
			line-height: 20px;
			color: $f-blue;

			@media #{ $smalldown } {
				font-size: 0.875rem;
			}
		}
	}

	&__list {
		position: relative;
		margin-top: 2.5rem;

		&--deets {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 1.5rem;

			&:last-child {
				margin-bottom: 0;
			}

			@media #{ $xsmalldown } {
				display: block;
			}

			p {
				font-weight: 500;
				line-height: 20px;
				text-transform: capitalize;

				@media #{ $xsmalldown } {
					margin-top: 0.875rem;
				}

				&:first-child {
					color: $gray;
					text-transform: capitalize;

					@media #{ $xsmalldown } {
						margin-top: 0;
					}
				}
			}
		}
	}

	.card-type {
		width: 32px;
		height: 24px;
		color: #fff3f3;
	}

	&__terms {
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 15px;
		text-align: center;
		letter-spacing: 0.01em;
		color: $gray;
		margin-top: 24px;
		width: 90%;
		margin-left: auto;
		margin-right: auto;

		a {
			color: $gray;
			text-decoration: underline;
		}

		span {
			text-transform: uppercase;
		}
	}
}
