.People {
	padding: 40px;

	&__error {
		margin-bottom: 30px;
		padding: 24px;
		background: #fff3f3;
		border-radius: 12px;

		h4 {
			color: $black;
			font-size: 14px;
			font-weight: 600;
			line-height: 21px;
			text-align: left;
		}

		li {
			color: #5c6368;
			font-size: 14px;
			font-weight: 400;
			line-height: 21px;
			text-align: left;
			margin-top: 6px;
		}

		&--list {
			display: flex;
			justify-content: space-between;

			.formats {
				list-style: disc;
			}
		}
	}

	&__header {
		h4 {
			color: $black;
			font-size: 24px;
			font-weight: 600;
			line-height: 29.05px;
			text-align: left;
		}

		.mini-text {
			color: #5c6368;
			font-size: 14px;
			font-weight: 400;
			line-height: 19.6px;
			text-align: left;
			margin-top: 12px;
		}

		&--pills {
			max-width: 768px;
			width: 100%;
			margin: 0 auto;
			margin-top: 30px;
			display: flex;
			height: 48px;
			justify-content: space-between;
			background-color: #f5f5f5;
			border-radius: 40px;
			overflow: hidden;

			.active {
				background-color: #005397;
				border-radius: 40px;

				p {
					color: #ffffff;
				}
			}
		}

		&--pill {
			width: 100%;
			cursor: pointer;

			> p {
				text-transform: uppercase;
				text-align: center;
				font-size: 1rem;
				font-weight: 600;
				line-height: 19.36px;
				padding-top: 1rem;
				padding-bottom: 1rem;
				color: #3d0814;

				@media #{ $smalldown } {
					font-size: 0.875rem;
				}
			}
		}

		&--filterContainer {
			position: relative;

			.filterList {
				position: absolute;
				z-index: 10001;
				top: 35px;
				right: 0px;
				width: 230px;
				padding: 16px;
				border-radius: 12px;

				background-color: #fff;
				border-width: 0px, 1px, 1px, 1px;

				border-style: solid;

				border-color: #f2f6f5;

				li {
					cursor: pointer;
					padding: 10px 7px;
					color: #5c6368;
					font-size: 14px;
					font-weight: 400;
					line-height: 20px;
					text-align: left;
					transition: all 0.5s;

					&:hover {
						background-color: #f5f5f5;
					}
				}
			}
		}

		&--filter {
			display: flex;
			align-items: center;
			min-width: 102px;
			width: 100%;
			height: 40px;
			justify-content: space-between;
			background-color: #f5f5f5;
			border: 1px solid #f2f6f5;
			padding: 0.875rem;
			border-radius: 6px;
			gap: 10px;
			cursor: pointer;

			> p {
				font-size: 14px;
				font-weight: 400;
				line-height: 17px;
				letter-spacing: 0em;
				text-align: left;
				color: $black;
				opacity: 0.8;
				text-transform: capitalize;
			}
		}

		&--title {
			p {
				font-weight: 600;
				font-size: 18px;
				line-height: 100%;
				letter-spacing: 0%;
				color: #5c6368;

				span {
					font-weight: 600;
					font-size: 18px;
					line-height: 100%;
					letter-spacing: 0%;
					color: #000b14;
				}
			}
		}
	}

	&__table {
		width: 100%;

		&--header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
		}

		&--body {
			border: 1px solid #f2f6f5;
			border-top-left-radius: 12px;
			border-top-right-radius: 12px;

			&--no_records {
				border-radius: 12px;

				&--container {
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					padding: 20px;
					gap: 12px;

					img {
						width: 165px;
					}

					.text {
						width: 100%;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						gap: 4px;

						&--title {
							font-size: 16px;
							font-weight: 500;
							line-height: 19.36px;
							color: #000;
							text-align: center;
							letter-spacing: 0.32px;
						}

						&--subtitle {
							font-size: 14px;
							font-weight: 400;
							line-height: 21px;
							color: #475467;
							text-align: center;
						}
					}
				}
			}
		}
	}

	&__details {
		&--container {
			border: 1px solid #f2f6f5;
			border-radius: 12px;
			margin-bottom: 24px;

			> h1 {
				font-weight: 600;
				font-size: 18px;
				line-height: 24px;
				letter-spacing: 0%;
				padding: 12px 30px;
				background-color: #f5f5f5;
				color: #000b14;
				overflow: hidden;
				border-top-left-radius: 12px;
				border-top-right-radius: 12px;
			}
		}

		&--deets {
			display: flex;
			padding: 20px 30px;
			border-bottom: 1px solid #f5f5f5;

			.deet {
				width: 40%;

				p {
					color: #5c6368;
					font-size: 12px;
					font-weight: 500;
					line-height: 14.52px;
					text-align: left;
					display: flex;
					align-items: center;
					gap: 8px;
					margin-bottom: 6px;
					text-transform: uppercase;
				}

				h4 {
					color: $black;
					font-size: 16px;
					font-weight: 500;
					line-height: 19.36px;
					text-align: left;
				}

				.label-grid {
					display: grid;
					grid-template-columns: 1fr 1fr 1fr;
					gap: 16px;

					span {
						color: $black;
						font-size: 16px;
						font-weight: 500;
						line-height: 19.36px;
						text-align: left;
						text-transform: capitalize;
					}
				}
			}
		}

		&--pass {
			.pass__deets {
				padding: 20px;
				background-color: #f5fbff;
				margin: 20px 0;
				border-radius: 12px;
				display: flex;
				align-items: center;

				> .form-group {
					margin-right: 10px;
					margin-bottom: 0px;
				}

				.deets--side {
					width: 40%;

					h4 {
						color: #005397;
						font-size: 12px;
						font-weight: 500;
						line-height: 14.52px;
						text-align: left;
						text-transform: uppercase;
						display: flex;
						align-items: center;
						gap: 8px;
					}

					> p {
						padding-top: 6px;
						color: #005397;
						font-size: 16px;
						font-weight: 500;
						line-height: 19.36px;
						text-align: left;
					}
				}

				.deets--btn {
					.__filterContainer {
						position: relative;

						.--filter {
							background-color: #005397;
							border-radius: 50px;
							padding: 12px 24px;
							display: flex;
							display: flex;
							gap: 10px;
							align-items: center;
						}
					}

					p {
						color: #ffff;
						font-size: 14px;
						font-weight: 500;
						line-height: 16.94px;
						text-align: left;
						cursor: pointer;
					}

					.filterList {
						position: absolute;
						z-index: 10001;
						top: 35px;
						width: 230px;
						padding: 16px;
						border-radius: 12px;

						background-color: #fff;
						border-width: 0px, 1px, 1px, 1px;

						border-style: solid;

						border-color: #f2f6f5;

						li {
							cursor: pointer;
							padding: 10px 7px;
							color: #5c6368;
							font-size: 14px;
							font-weight: 400;
							line-height: 20px;
							text-align: left;
							transition: all 0.5s;

							&:hover {
								background-color: #f5f5f5;
							}
						}
					}
				}

				.deets--date {
					background-color: #005397;
					padding: 12px 24px;
					color: #fff;
					border-radius: 50px;
					cursor: pointer;
					font-size: 14px;
					font-weight: 500;
					line-height: 16.94px;
					text-align: center;

					p {
						color: white;
					}
				}
			}

			hr {
				border: none;
				border-bottom: 1px solid #f5f5f5;
			}
		}
	}

	.header--btn {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__body {
		max-width: 768px;
		width: 100%;
		margin: 0 auto;
		margin-top: 60px;
	}

	&__footer {
		height: 80px;
		position: fixed;
		bottom: 0px;
		left: 0px;
		width: 100%;
		background-color: #e7f5ff;

		&--container {
			max-width: 90%;
			width: 100%;
			height: 100%;
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: space-between;

			@media #{ $mediumdown } {
				max-width: 100%;
				padding: 0 1rem;
			}
		}

		.__left {
			display: flex;
			gap: 1rem;

			@media #{ $mediumdown } {
				display: none;
			}

			.selected {
				display: flex;
				gap: 6px;
				align-items: center;
				font-family: Inter;
				font-size: 16px;
				font-weight: 500;
				line-height: 19.36px;
				color: #5c6368;

				> span {
					background-color: #005397;
					width: 40px;
					height: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 100px;
					font-size: 10px;
					font-weight: 500;
					line-height: 12.1px;
					color: #fff;
				}
			}

			.deselect {
				font-size: 16px;
				font-weight: 500;
				line-height: 19.36px;
				color: #5c6368;
				background: #f5f5f5;
				padding: 12px 24px;
				border-radius: 100px;
				cursor: pointer;
			}
		}

		.__right {
			font-size: 16px;
			font-weight: 500;
			line-height: 19.36px;
			color: #000b14;
			font-family: Inter;

			@media #{ $mediumdown } {
				width: 100%;
				font-size: 14px;
				font-weight: 500;
				line-height: 16.94px;
				text-align: left;
			}

			span {
				background-color: #005397;
				color: #fff;
				padding: 12px 24px;
				margin-left: 12px;
				border-radius: 40px;
				cursor: pointer;

				@media #{ $mediumdown } {
					padding: 6px 12px;
				}
			}
		}
	}
	&__hint {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: #f5fbff;
		width: 100%;
		padding: 16px;
		border-radius: 8px;
		margin-bottom: 30px;
		border-left: 8px solid #005397;
		p {
			font-weight: 500;
			font-size: 16px;
			line-height: 150%;
			color: #000b14;
			letter-spacing: 0%;
			display: flex;
			gap: 12px;
		}
		h4 {
			font-weight: 600;
			font-size: 18px;
			line-height: 100%;
			letter-spacing: 0%;
			color: #005397;
			text-transform: capitalize;
			cursor: pointer;
		}
	}
}

.validation {
	margin-top: 80px;
	padding: 30px 120px;
	&__header {
		h1 {
			font-weight: 600;
			font-size: 24px;
			line-height: 100%;
			letter-spacing: 0%;
			color: #000b14;
		}
		&--body {
			display: flex;
			gap: 20px;
			margin-top: 12px;
			.__left {
				background-color: #f5fbff;
				width: 50%;
				border: 1px solid #e7f5ff;
				padding: 20px;
				border-radius: 12px;
				h2 {
					font-weight: 600;
					font-size: 12px;
					line-height: 150%;
					letter-spacing: 0%;
					color: #005397;
				}
				ul {
					margin-top: 12px;
				}
				li {
					font-weight: 400;
					font-size: 12px;
					line-height: 150%;
					letter-spacing: 0%;
					vertical-align: middle;
					color: #5c6368;
					display: flex;
					align-items: center;
					gap: 6px;
					margin-bottom: 6px;
				}
			}
			.__right {
				background-color: #f5fbff;
				border: 1px solid #e7f5ff;
				width: 50%;
				padding: 20px;
				border-radius: 12px;
				&--header {
					display: flex;
					justify-content: space-between;
					align-items: center;
					h2 {
						font-weight: 600;
						font-size: 12px;
						line-height: 150%;
						letter-spacing: 0%;
						color: #e0063c;
						display: flex;
						gap: 6px;
						align-items: center;
					}
					p {
						font-weight: 600;
						font-size: 16px;
						line-height: 100%;
						letter-spacing: 0%;
						text-decoration: underline;
						color: #005397;
						cursor: pointer;
					}
				}
				ol {
					padding: 6px 20px;

					li {
						font-weight: 400;
						font-size: 12px;
						line-height: 150%;
						letter-spacing: 0%;
						color: #5c6368;
						.title {
							font-weight: 600;
							color: #000b14;
						}
						span {
							font-weight: 600;
							font-size: 12px;
							line-height: 150%;
							letter-spacing: 0%;
							color: #5c6368;
						}
						&::marker {
							font-weight: 600;
							color: #000b14;
						}
					}
				}
			}
		}
	}
	&__body {
		margin-top: 90px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 30px;
		width: 100%;
		&--left {
			border: 1px solid #005397;
			h4 {
				background-color: #f5fbff;
				color: #005397;
			}
		}
		&--right {
			border: 1px solid #e0063c;
			h4 {
				background-color: #fff3f3;
				color: #e0063c;
			}
		}
		.card {
			width: 50%;
			display: flex;
			gap: 20px;
			height: 144px;
			padding: 12px 16px;
			border-radius: 8px;
		}
	}
	&__details {
		h4 {
			padding: 6px 8px;
			font-weight: 700;
			font-size: 24px;
			line-height: 100%;
			letter-spacing: 0%;
			width: fit-content;
		}
		p {
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			letter-spacing: 0%;
			color: #344054;
			margin-top: 6px;
		}
	}
	&__table {
		margin-top: 30px;
		> p {
			color: #e0063c;
			font-family: Inter;
			font-weight: 600;
			font-size: 16px;
			line-height: 140%;
			letter-spacing: 2%;
			display: flex;
			align-items: center;
			gap: 6px;
		}
	}
	&__btns {
		display: flex;
		margin-top: 50px;
		gap: 30px;
	}
	&__hint {
		display: flex;
		align-items: center;
		gap: 12px;
		background-color: #f5fbff;
		width: 100%;
		padding: 16px;
		border-radius: 8px;
		margin-top: 30px;
		p {
			font-weight: 500;
			font-size: 16px;
			line-height: 150%;
			color: #005397;
			letter-spacing: 0%;
		}
	}
}
