table {
	width: 100%;
	overflow-x: scroll;
	// background-color: $primary-light-2;
	border-collapse: collapse;
	@media #{ $mediumdown } {
		display: none;
	}

	// background-color: red;
	// padding: 0 32px;
	// padding-right: 20px;
	tr {
		height: 56px;
		border-radius: 15px;
		padding: 0 32px;

		th {
			border: none;
			border-bottom: 1px solid #f5f5f5;
			background: $primary-light-2;
			text-align: left;
			padding: 0 32px;
			font-weight: 600;
			font-size: 0.75rem;
			line-height: 15px;
			letter-spacing: 0.004em;
			text-transform: uppercase;
			color: $gray;
			border: none;
			&:first-child {
				border-top-left-radius: 15px;
			}
			&:last-child {
				border-top-right-radius: 15px;
			}

			// border-collapse: collapse;
		}
		.transaction-th {
			background: #f5fbff;
			text-align: left;
			padding: 0 20px;
			font-weight: 600;
			font-size: 12px;
			line-height: 150%;
			letter-spacing: 0.004em;
			text-transform: uppercase;
			color: #5C6368;

			// border-collapse: collapse;
		}
		.blueHeader {
			background-color: #f5fbff;
		}
		.transparentHeader {
			background-color: #fff;
		}
	}

	tr {
		&:hover {
			background-color: #f5f5f5 !important;
		}

		.delete-row {
			color: #e0063c;

			&:hover {
				text-decoration: underline;
				font-weight: 500;
			}
		}

		td {
			position: relative;
			padding: 8px 0px;
			padding-left: 32px;
			font-weight: 400;
			font-size: 0.875rem;
			line-height: 17px;
			color: rgba(0, 11, 20, 0.8);
			// background-color: #ffffff;
			border-bottom: 1px solid #f5f5f5;
			justify-content: left;
			text-transform: capitalize;
			transition: 0.3s;
			&:last-child {
				cursor: pointer;
			}
		}
		.plan-table {
			padding: 16px 0px;
			padding-left: 32px;
			border-top: 1px solid #f5f5f5;
			border-bottom: none;
			text-transform: capitalize;


			.label-container{
				position: relative;

				.label-field{
					font-size: 12px;
					padding: 4px 6px;
					border-radius: 4px;
					color: #029AFF;
					background-color: rgba(2, 154, 255, 0.1);
					font-weight: 500;
					height: 23px;
					margin-right: 6px;
					text-transform: capitalize;
					
		
					
				}
				.action-icon{
					box-sizing: border-box;
					cursor: pointer;
					height: 23px;
					width: 23px;
					border: 1px solid #F2F6F5;
					border-radius: 4px;
					padding: 3px 4px;
					padding-top: 2px;
					
				}
				.label-dropdown{
					position: absolute;
					top: 23px;
					left: 0;
					background-color: #fff;
					border: 1px solid #F2F6F5;
					border-radius: 8px;
					z-index: 100;
					min-width: 119px;
					padding: 16px;
					gap: 12px;
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: flex-start;
					box-shadow: 0px 0px 8px rgba(16, 24, 40, 0.04), 0px 4px 6px -2px rgba(16, 24, 40, 0.05);

					.label-item{
						font-size: 14px;
						line-height: 20px;
						font-weight: 400;
						color: #000B14;
						text-transform: capitalize;
						
					}
				}
				
			}
		}

		.address-cell{
			width: 300px;
			text-wrap: wrap;


		}
		.transaction-table {
			padding: 20px 0px;
			padding-left: 20px;
			border-top: 1px solid #F2F6F5;
			border-bottom: none;
			text-transform: capitalize;
			font-size: 12px;
			color: #5C6368;
			line-height: 150%;
			letter-spacing: 0%;

			.status {
				padding: 8px 16px;
				width: 49px;
				height: 31px;
				border-radius: 100px;
				text-transform: uppercase;
				font-weight: 600;
				font-size: 12px;
				letter-spacing: 2%;
				font-weight: 600;
				text-transform: uppercase;
				display: flex;
				align-items: center;
				justify-content: center;
			
			}
			.dr{
				background: #FFF3F3;
				color: #E0063C;
			}
			.cr{
				background: #EBF7EE;
				color: #3BAD54;
			}
			.action-icon{
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				width: 40px;
				height: 40px;
				border-radius: 8px;
				border: 1px solid #F2F6F5;
			}
		}
		.action-icon-cell{
			padding-left: 0;
			padding-right: 10px;
		}
		.expanded{
			background: #FCFEFF;
			gap: 6px;
			.column-name{
				display: block;
				font-weight: 600;
				font-size: 12px;
				line-height: 18px;
				color: #5C6368;
				text-transform: uppercase;
			}
			.data-value{
				display: block;
				font-weight: 400;
				font-size: 12px;
				line-height: 18px;
				color: #000B14;
			}
		}
		.bottom{
			border-top: 0px solid #F2F6F5;
		}
		
		// .duration {
		// }
		.status {
			padding: 8px 16px;
			border-radius: 100px;
			text-transform: uppercase;
			font-weight: 600;
			font-size: 0.75rem;
			text-transform: uppercase;
		}
		.employee-active {
			background: #ebf7ee;

			color: #3bad54;
		}
		.employee-active {
			background: #ebf7ee;
			color: #3bad54;
		}
		.employee-inactive {
			background: #f5f5f5;
			color: $gray;
		}
		.pending {
			color: #f79f1a;
			background: #fef1dd;
		}
	}
}
.grey-bg {
	transition: 0.5s;
	&:hover {
		background-color: #f5f5f5 !important;
	}
}

.smaller__table {
	display: none;
	@media #{ $mediumdown } {
		display: block;
		padding: 0px 24px;
		.list-container {
			border-bottom: 1px solid #f5f5f5;
			.table__head {
				position: relative;
				margin-top: 16px;
				display: flex;
				justify-content: space-between;
			}
			p {
				font-weight: 400;
				font-size: 0.87rem;
				line-height: 17px;
				color: rgba(0, 11, 20, 0.8);
				// opacity: 0.8;
				padding: 16px 0;
			}
		}
	}
}
.status {
	font-weight: 500;
	font-size: 14px;
	line-height: 16.94px;
	letter-spacing: 0%;
	padding: 4px 8px !important;
	display: flex;
	align-items: center;
	width: fit-content;
	gap: 6px;
}
.statusCritical {
	background-color: #fff3f3;
	color: #e0063c;
}
.statusWarning {
	color: #e98c00;
	background-color: #fff7f3;
}
.statusExpired {
	color: #5f6062;
	background-color: #f5f5f5;
}

.statusExpiredCircle {
	color: #5f6062;
	fill: #5f6062;
	stroke: #5f6062;
}
.statusCriticalCircle {
	color: #e0063c;
	fill: #e0063c;
	stroke: #e0063c;
}
.statusWarningCircle {
	color: #e98c00;
	fill: #e98c00;
	stroke: #e98c00;
}

.btnText {
	font-weight: 500;
	font-size: 14px;
	line-height: 16.94px;
	letter-spacing: 0%;
	text-decoration: underline;
	color: #005397;
}
