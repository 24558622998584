.settings {
	padding: 40px;
	@media #{ $largedown } {
		padding: 24px;
	}

	&__header {
		display: flex;
		gap: 24px;
		margin-bottom: 40px;
		border-bottom: 1px solid #f5f5f5;
	}
	.--link {
		font-weight: 500;
		font-size: 0.875rem;
		line-height: 150%;
		color: $gray;
		padding-bottom: 17px;
		transition: 0.3s;
		&:hover {
			color: $black;
			font-weight: 600;
		}
	}
	.selected {
		border-bottom: 2px solid #005397;
		color: #005397;
		&:hover {
			color: #005397;
			font-weight: 600;
		}
	}

	.settings__container {
		&--header {
			border-radius: 12px;
			padding: 30px;
			border: 1px solid #d3d3d399;
			@media #{ $mediumdown } {
				padding: 0px;
				border: none;
			}
			h2 {
				font-weight: 500;
				font-size: 1rem;
				line-height: 20px;
				color: $black;
			}
			p {
				font-weight: 400;
				font-size: 0.875rem;
				line-height: 24px;
				letter-spacing: 0.004em;
				color: $gray;
				margin-top: 4px;
			}
			.img__container {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 96px;
				height: 96px;
				background: #fffcfc;
				border: 1px solid #ff8788;
				color: #3d0814;
				border-radius: 50%;
				font-weight: 500;
				font-size: 1.875rem;
				line-height: 36px;
				color: #3d0814;
				margin-top: 18px;
			}
			span {
				font-weight: 400;
				font-size: 0.75rem;
				line-height: 15px;
				color: $gray;
				margin-top: 10px;
			}
		}
		&--body {
			border-radius: 12px;
			padding: 30px;
			border: 1px solid #d3d3d399;
			width: 100%;
			margin-top: 40px;
			@media #{ $mediumdown } {
				padding: 0px;
				border: none;
			}
			h2 {
				font-weight: 500;
				font-size: 1.125rem;
				line-height: 22px;
				color: $black;
			}
			p {
				font-weight: 400;
				font-size: 0.875rem;
				line-height: 24px;
				letter-spacing: 0.004em;
				color: $gray;
				margin-top: 4px;
			}
			.newcard {
				font-weight: 500;
				font-size: 1rem;
				line-height: 150%;
				text-decoration-line: underline;
				margin-top: 24px;
				color: #3d0814;
				cursor: pointer;
			}
			.settings_form {
				margin-top: 24px;
				width: 586px;
				@media #{ $largedown } {
					width: 100%;
				}
				.form-group {
					&:last-child {
						margin-bottom: 0px;
					}
				}
				> p {
					font-style: italic;
					font-weight: 400;
					font-size: 0.875rem;
					line-height: 17px;

					letter-spacing: 0.02em;
					color: $gray;
				}
			}
			.card__container {
				margin-top: 16px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				background-color: #fff3f3;
				border-radius: 0.625rem;
				border: 1px solid #ff8788;
				padding: 1.5rem;
				max-width: 34.625rem;
				.card-details {
					.numbers {
						display: flex;
						align-items: center;
						gap: 0.5rem;
						font-size: 1rem;
						font-style: normal;
						font-weight: 500;
						.default {
							color: #3d0814;
							font-size: 0.75rem;
							font-style: normal;
							font-weight: 500;
							background-color: #fff;
							padding: 0.25rem;
							border-radius: 0.25rem;
						}
					}
					.date {
						font-size: 0.875rem;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
						margin-top: 0.5rem;
						display: block;
					}
				}
				.card__options {
					display: flex;
					gap: 1rem;
					span {
						cursor: pointer;
						&:first-child {
							color: #5c6368;
							font-size: 0.875rem;
							font-style: normal;
							font-weight: 400;
							line-height: normal;
							text-decoration-line: underline;
						}
						&:last-child {
							color: #3d0814;
							font-size: 0.875rem;
							font-style: normal;
							font-weight: 400;
							line-height: normal;
							text-decoration-line: underline;
						}
					}
				}
			}
			.cac__container {
				margin-top: 16px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				background-color: #fff;
				border-radius: 0.625rem;
				border: 1px solid #f5f5f5;
				padding: 1.5rem;
				max-width: 48.625rem;
			}

			.upload {
				display: flex;
				gap: 1.5rem;
				justify-content: center;
				> span {
					width: 3rem;
					height: 3rem;
					border-radius: 0.25rem;
					background: #fff3f3;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.upload__text {
					> h4 {
						color: #000b14;
						font-size: 1rem;
						font-style: normal;
						font-weight: 600;
						line-height: 1.5rem; /* 150% */
						letter-spacing: 0.004rem;
					}
					> span {
						color: #5c6368;
						font-size: 0.85rem;
						font-style: normal;
						font-weight: 400;
						line-height: 1.5rem; /* 150% */
						letter-spacing: 0.004rem;
					}
				}
			}
			.upload__status {
				> span {
					color: #3d0814;
					font-size: 0.875rem;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
					letter-spacing: 0.0175rem;
					text-transform: uppercase;
					padding: 1rem 2rem;
					background-color: #f5f5f5;
					cursor: pointer;
					border-radius: 0.25rem;
				}
			}
		}
		&--notifications {
			border-radius: 12px;
			padding: 30px;
			border: 1px solid #d3d3d399;
			margin-top: 40px;
			@media #{ $mediumdown } {
				padding: 0px;
				border: none;
			}
			h2 {
				font-weight: 500;
				font-size: 1.125rem;
				line-height: 22px;
				color: $black;
			}
			p {
				font-weight: 400;
				font-size: 0.875rem;
				line-height: 24px;
				letter-spacing: 0.004em;
				color: $gray;
				margin-top: 4px;
			}
			.notifications {
				display: flex;
				align-items: center;
				gap: 8px;
				padding: 42px 24px;
				p {
					font-size: 14px;
					font-weight: 500;
					line-height: 16.94px;
					text-align: left;
					text-underline-position: from-font;
					text-decoration-skip-ink: none;
				}
			}
		}
		.upload__completed {
			color: #3bad54;
			text-align: center;
			font-size: 0.75rem;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			letter-spacing: 0.015rem;
			text-transform: uppercase;
			padding: 0.5rem 1rem;
			border-radius: 6.25rem;
			background: #ebf7ee;
		}
	}
}
.credentials {
	border: 1px solid #d3d3d399;
	border-radius: 12px;
	padding: 30px;
	> h3 {
		color: $black;
		font-size: 24px;
		font-weight: 600;
		line-height: 29.05px;
		text-align: left;
	}
	> p {
		color: #5c6368;

		font-size: 14px;
		font-weight: 400;
		line-height: 19.6px;
		margin-top: 12px;
	}
	&--body {
		display: flex;
		justify-content: space-between;

		align-items: center;
		margin-top: 30px;
		> h4 {
			color: $black;
			font-size: 18px;
			font-weight: 600;
			line-height: 21.78px;
			text-align: right;
		}
		.input--field {
			width: 60%;
			height: 60px;
			border-radius: 8px;
			border: 1px solid #d3d3d399;
			cursor: pointer;
			display: flex;
			align-items: center;
			padding: 0px 18px;
			justify-content: space-between;
			> input {
				width: 94%;
				height: 100%;
				outline: none;
				background-color: transparent;
				border: none;
				font-size: 16px;
				text-align: left;
				filter: blur(3px);
				border: 0;
				padding: 21px 0px;
			}
			.visible {
				filter: none;
			}
		}
		.btn {
			display: flex;
			gap: 20px;
		}
	}
}
