//Utilities

.status-completed {
	color: $success;
	background: $success-light;
	border-radius: 100px;
}

.status-pending {
	color: $pending;
	background: $pending-light;
	border-radius: 100px;
}

.uploadedItems {
	position: relative;
	margin-top: 2.5rem;
}

.item-upload {
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-height: 45px;
	padding: 0.625rem 1rem;
	background: #fcfbfb;
	border-radius: 8px;
	margin-bottom: 1rem;

	&:last-child {
		margin-bottom: 0;
	}

	@media #{ $largeup } {
		padding: 0.625rem 1.5rem;
	}

	@media #{ $xsmalldown } {
		display: block;
		align-items: unset;
		justify-content: unset;
	}

	&__left,
	&__right {
		position: relative;
		display: flex;
		align-items: center;

		@media #{ $xsmalldown } {
			justify-content: center;
		}
	}

	&__left {
		.filename {
			font-size: 1rem;
			font-weight: 500;
			line-height: 24px;
			letter-spacing: 0.004em;
			margin-left: 1rem;
		}
	}

	&__right {
		color: $gray;

		@media #{ $xsmalldown } {
			margin-top: 1.125rem;
		}

		svg {
			width: 12px;
			height: 12px;
			margin-left: 1rem;
			cursor: pointer;
			transition: color 0.25s ease-in-out;

			&:hover {
				color: $primary;
			}

			@media #{ $largeup } {
				margin-left: 1.5rem;
			}
		}
	}
}

.password-rules {
	position: relative;
	margin-top: 24px;
	max-width: 342px;

	.lineIndicator {
		position: relative;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 1.25rem;

		.div {
			height: 2px;
			background-color: #ced4da;
		}

		.div.passed {
			background-color: $success;
		}
	}

	.actualRules {
		position: relative;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 1rem;
		margin-top: 1.5rem;

		@media #{ $xsmalldown } {
			grid-template-columns: repeat(1, 1fr);
		}

		.rule {
			font-size: 0.75rem;
			font-weight: 400;
			line-height: 15px;
			color: $gray;
			display: flex;
			align-items: center;

			.circle {
				width: 10px;
				height: 10px;
				background-color: #ced4da;
				border-radius: 100%;
				margin-right: 0.5rem;
			}
		}

		.active {
			.circle {
				background-color: $success !important;
			}

			p {
				color: $black;
			}
		}
	}
}

.renewal-banner {
	padding: 0.88rem 1.5rem;
	width: 100%;
	background-color: #ffdaa1;
	border-radius: 8px;
	margin-bottom: 1.5rem;

	@media #{ $largedown } {
		grid-gap: 1.25rem;
	}

	@media #{ $mediumup } {
		display: flex;
		align-items: center;
		justify-content: center;
		grid-gap: 2rem;
	}

	p {
		position: relative;
		font-size: 0.875rem;
		font-weight: 500;
		line-height: 19px;
		color: $black;
		margin-bottom: 1rem;

		@media #{ $mediumup } {
			margin-bottom: 0;
		}

		@media #{ $largeup } {
			font-size: 1rem;
		}
	}

	a {
		color: $f-blue;
		border: 1px solid $f-blue;
		height: 32px;
	}
}

// Display
.d-none {
	display: none;
}

.d-blk {
	display: block;
}

.d-iblk {
	display: inline-block;
}

.d-flx,
%flx-space {
	display: flex;
}

.d-iflx {
	display: inline-flex;
}

.flx-inverse {
	flex-direction: row-reverse;

	&--medium {
		@media #{ $mediumup } {
			flex-direction: row-reverse;
		}
	}
}

.flex-col {
	flex-direction: column;

	&--medium {
		@media #{ $mediumdown } {
			flex-direction: column;
		}
	}

	&--small {
		@media #{ $smalldown } {
			flex-direction: column;
		}
	}
}

.flex-auto {
	flex: auto;
}

.flex-wrap {
	flex-wrap: wrap;
}

.flex-nowrap {
	flex-wrap: nowrap;
}

.al-i-fs {
	align-items: flex-start;
}

.al-i-fe {
	align-items: flex-end;
}

.al-i-c {
	align-items: center;
}

.al-s-e {
	align-self: flex-end;
}

.al-s-bl {
	align-self: baseline;
}

.j-c-sb,
%flx-space {
	justify-content: space-between;

	&--small {
		@media #{ $smalldown } {
			justify-content: space-between;
		}
	}
}

.pointer {
	cursor: pointer;
}

.j-c-c {
	justify-content: center;
}

.j-c-fe {
	justify-content: flex-end;
}

.j-c-e {
	justify-content: end;
}

.d-flx-space {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.flx-space {
	&--center {
		@extend %flx-space;
		align-items: center;
	}

	&--baseline {
		@extend %flx-space;
		align-items: baseline;
	}

	&--fe {
		@extend %flx-space;
		align-items: flex-end;
	}
}

.flx-center {
	@extend .d-flx;
	@extend .al-i-c;
}

.flx-top {
	@extend .d-flx;
	@extend .al-i-c;
}

.iflx-center {
	@extend .d-iflx;
	@extend .al-i-c;
}

.iflx-center-center {
	@extend .d-iflx;
	@extend .al-i-c;
	@extend .j-c-c;
}

.flx-j-center {
	@extend .d-flx;
	@extend .j-c-c;
}

.flx-center-center {
	@extend .d-flx;
	@extend .al-i-c;
	@extend .j-c-c;
}

.flx-center-col {
	@extend .d-iflx;
	@extend .j-c-sb;
	@extend .flex-col;
}

.flx-center-center-col {
	@extend .d-iflx;
	@extend .al-i-c;
	@extend .j-c-c;
	@extend .flex-col;
}

.flx-center-wrap {
	@extend .flx-center;
	flex-wrap: wrap;
}

.flx-space-wrap {
	@extend .d-flx-space;
	flex-wrap: wrap;
}

.flx-space-wrap-auto {
	@extend .d-flx;
	@extend .flex-wrap;
	@extend .flex-auto;
	@extend .j-c-sb;
}

.flx-1 {
	flex: 1;
}

// Margins
.mx-auto {
	margin-right: auto;
	margin-left: auto;
}

.m-0 {
	margin: 0 !important;
}

.mx-8 {
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}

.my-8 {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}

.mr-6 {
	margin-right: 0.375rem;
}

.mr-8 {
	margin-right: 0.5rem;
}

.mr-14 {
	margin-right: 0.875rem;
}

.ml-4 {
	margin-left: 0.25rem;
}

.ml-8 {
	margin-left: 0.5rem;
}

.mt-4 {
	margin-top: 0.25rem;
}

.mt-8 {
	margin-top: 0.5rem;
}

.mt-16 {
	margin-top: 1rem;
}

.mt-24 {
	margin-top: 1.5rem;
}

.mt-30 {
	margin-top: 1.875rem;
}

.mt-32 {
	margin-top: 2rem;
}

.mt-40 {
	margin-top: 2.5rem;
}

.mt-64 {
	margin-top: 4rem;
}

.mb-8 {
	margin-bottom: 0.5rem;
}

.mb-16 {
	margin-bottom: 1rem;
}

.mb-24 {
	margin-bottom: 1.5rem;
}

.mb-32 {
	margin-bottom: 2rem;
}

// Paddings
.p-0 {
	padding: 0 !important;
}

.px-8 {
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}

.py-8 {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.pr-8 {
	padding-right: 0.5rem;
}

.pl-8 {
	padding-left: 0.5rem;
}

.pt-8 {
	padding-top: 0.5rem;
}

.pb-8 {
	padding-bottom: 0.5rem;
}

// Media Query Utilities
.hide-on-mobile {
	@media #{ $smalldown } {
		display: none !important;
	}
}

.hide-on-medium {
	@media #{ $mediumdown } {
		display: none !important;
	}
}

.show-smalldown {
	display: none;

	@media #{ $smalldown } {
		display: block;
	}
}

.show-mediumdown {
	display: none;

	@media #{ $mediumdown } {
		display: block;
	}
}

.show-smallup {
	display: none;

	@media #{ $smallup } {
		display: block;
	}
}

.show-mediumup {
	display: none;

	@media #{ $mediumup } {
		display: block;
	}
}

.hide-mediumup {
	display: block;

	@media #{ $mediumup } {
		display: none;
	}
}

.hide-largeup {
	display: block;

	@media #{ $largeup } {
		display: none;
	}
}

.show-largeup {
	display: none;

	@media #{ $largeup } {
		display: block;
	}
}

.popUp-container {
	background-color: #fef1dd;
	display: flex;
	width: 100%;
	padding: 0.88rem 1.5rem;
	gap: 0.8rem;
	border-radius: 8px;
	border: 1px dashed #f79f1a;
	margin-bottom: 1.5rem;

	p {
		color: $black;
		font-size: 0.875rem;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;

		span {
			font-weight: 700;
		}
	}
}

// Screen Reader Text
// The .sr class ensure element is available in the DOM but only to screen readers. The whitespace: nowrap rule ensures accessible texts aren't smushed off screen.
// @link https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
.sr:not(:focus):not(:active) {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	border: 0;
	clip-path: inset(100%);
	overflow: hidden;
	white-space: nowrap;
}

@keyframes l3 {
	to {
		transform: rotate(1turn);
	}
}

.mini-loader {
	margin: 0 auto;
	width: 15px;
	padding: 4px;
	aspect-ratio: 1;
	border-radius: 50%;
	background: $primary;
	--_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
	-webkit-mask: var(--_m);
	mask: var(--_m);
	-webkit-mask-composite: source-out;
	mask-composite: subtract;
	animation: l3 1s infinite linear;
}
.big-loader {
	margin: 0 auto;
	width: 80px;
	padding: 4px;
	aspect-ratio: 1;
	border-radius: 50%;
	background: #005397;
	--_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
	-webkit-mask: var(--_m);
	mask: var(--_m);
	-webkit-mask-composite: source-out;
	mask-composite: subtract;
	animation: l3 1s infinite linear;
}

.search-filter-container {
	display: flex;
	align-items: center;
	justify-content: end;
	gap: 20px;

	.filter--container {
		display: flex;
		align-items: center;
		justify-content: start;
		gap: 20px;

		.filter-btn {
			border: none;
		}
	}

	.search--container {
		display: flex;
		gap: 12px;
		align-items: center;

		&__btn {
			width: 86px;
		}
	}

	.search {
		display: flex;
		align-items: center;
		width: 413px;
		height: 44px;
		border: 1px solid rgba(211, 211, 211, 0.6);
		border-radius: 8px;
		background: #f5f5f5;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 6px;
		padding: 16px;
		cursor: pointer;

		// svg {
		// 	opacity: 0.4;
		// }
		@media #{ $xs-md-down } {
			width: 100%;
		}

		input {
			outline: none;
			border: none;

			&::placeholder {
				color: #5c6368;
			}

			font-weight: 400;
			font-size: 16px;
			line-height: 120%;
			color: $black;
			width: 95%;
			background: transparent;
		}
	}

	.filter-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: 125px;
		height: 44px;
		border: 1px solid rgba(211, 211, 211, 0.6);
		border-radius: 8px;
		background: #f5f5f5;
		gap: 8px;
		padding: 16px;
		cursor: pointer;
		position: relative;

		span {
			color: #858585;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			text-transform: capitalize;
		}

		&--dropdown {
			width: 226px;
			height: 180px;
			background: #ffffff;
			border: 1px solid rgba(211, 211, 211, 0.6);
			border-radius: 8px;
			position: absolute;
			top: 105%;
			right: 0;
			display: flex;
			flex-direction: column;
			align-items: start;
			justify-content: start;
			padding: 16px 0;
			z-index: 50;

			&--item {
				color: #858585;
				flex: 1;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				cursor: pointer;
				width: 100%;
				padding: 0 16px;
				display: flex;
				justify-content: start;
				align-items: center;
				max-height: 44px;

				&:hover {
					background-color: #f5f5f5;
				}

				.empty {
					text-align: center;
				}
			}
		}

		.--dropdown__left {
			left: 0;
		}
	}
}

//Headway badge

.headway-changelog-widget {
	background-color: #005397;
	border-radius: 8px;
	width: 100%;
	height: 27px;
	padding: 6px 12px;
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-between;
	cursor: pointer;
	text-wrap: nowrap;

	&--left {
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: start;
		gap: 6px;

		span {
			font-size: 12px;
			color: #fff;
			font-weight: 500;
		}
	}
}

#HW_badge {
	background: #005397 !important;
}

.custom-multi-input {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	padding: 18px 24px;
	gap: 10px;

	width: 100%;
	min-height: 56px;

	background: #ffffff;
	border: 1px solid rgba(211, 211, 211, 0.6);
	border-radius: 8px;

	&--label {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 0px;
		gap: 6px;
		border-radius: 8px;
		background-color: #ececec;
		padding: 8px 4px;
		cursor: pointer;
		min-width: 98px;
		min-height: 24px;
		text-transform: capitalize;

		span {
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
			letter-spacing: 0%;
			color: #5c6368;

		}
	}
	&--input {
		border: none;
		outline: none;
		flex: 1;
		min-width: 120px;
		font-weight: 400;
		font-size: 16px;
		line-height: 20px;
		letter-spacing: 0%;
		background: transparent;
		&::placeholder {
			color: rgba(113, 113, 113, 0.6);
		}
	}
}

.custom__date--container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 8px;

	width: 100%;

	.date-picker-inputs {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		gap: 8px;

		span {
			font-size: 14px;
			font-weight: 400;
			color: #5c6368;
		}
	}

	.react-date-picker {
		display: inline-flex;
		position: relative;
	}

	.react-date-picker,
	.react-date-picker *,
	.react-date-picker *:before,
	.react-date-picker *:after {
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

	.react-date-picker--disabled {
		color: #6d6d6d;
	}

	.react-date-picker__wrapper {
		display: flex;
		flex-grow: 1;
		flex-shrink: 0;
		padding: 0;
	}

	.react-date-picker__inputGroup {
		width: fit-content;
		flex-grow: 1;
		padding: 0 2px;
		box-sizing: content-box;
		padding: 0;

		&:focus-within {
			.react-date-picker__inputGroup__divider,
			.react-date-picker__inputGroup__input {
				color: #000000;
			}
		}
	}

	.react-date-picker__inputGroup__divider {
		padding: 1px 0;
		white-space: pre;
		color: #5c6368;
	}

	.react-date-picker__inputGroup__divider,
	.react-date-picker__inputGroup__leadingZero {
		display: inline-block;
		font: inherit;
	}

	.react-date-picker__inputGroup__input {
		min-width: 0.54em;
		height: 100%;
		position: relative;
		padding: 0 1px;
		border: 0;
		background: none;
		color: currentColor;
		font: inherit;
		box-sizing: content-box;
		-webkit-appearance: textfield;
		-moz-appearance: textfield;
		appearance: textfield;
	}

	.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
	.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		margin: 0;
	}

	.react-date-picker__inputGroup__input--hasLeadingZero {
		margin-left: -0.54em;
		padding-left: calc(1px + 0.54em);
	}

	.react-date-picker__button {
		display: none;
	}

	.react-date-picker__button svg {
		display: none;
	}

	.react-date-picker__calendar {
		top: 30px !important;
		width: 350px;
		max-width: 100vw;
		z-index: 1;
		display: flex;
		flex-direction: row;
		align-items: end;
		justify-content: end;
	}

	.react-date-picker__calendar--closed {
		display: none;
	}

	.react-date-picker__calendar .react-calendar {
		border-width: thin;
	}

	.react-calendar {
		width: 284px;
		max-width: 100%;
		background: white;
		border: 1px solid #f3f3f3;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
			0px 4px 12px rgba(0, 0, 0, 0.08);
		padding: 16px;
		border-radius: 8px;
		line-height: 1.125em;
	}

	.react-calendar--doubleView .react-calendar__viewContainer {
		display: flex;
		margin: -0.5em;
	}

	.react-calendar,
	.react-calendar *,
	.react-calendar *:before,
	.react-calendar *:after {
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

	.react-calendar button {
		margin: 0;
		border: 0;
		outline: none;
	}

	.react-calendar button:enabled:hover {
		cursor: pointer;
	}

	.react-calendar__navigation {
		display: flex;
		height: 44px;
		margin-bottom: 1em;
		span {
			font-size: 15px;
			font-weight: 500;
			line-height: 140%;
			color: #000b14;
		}
	}

	.react-calendar__navigation button {
		min-width: 44px;
		background: none;
	}

	.react-calendar__navigation button:enabled:hover,
	.react-calendar__navigation button:enabled:focus {
		background-color: #e6e6e6;
	}

	.react-calendar__month-view__weekdays {
		text-align: center;
		text-transform: capitalize;

		font: inherit;
		font-size: 15px;
		font-weight: 400;

		abbr {
			text-decoration: none;
			line-height: 150%;
			color: #5c6368;
		}
	}

	.react-calendar__month-view__weekdays__weekday {
		width: 36px;
		height: 36px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.react-calendar__month-view__days__day--neighboringMonth,
	.react-calendar__decade-view__years__year--neighboringDecade,
	.react-calendar__century-view__decades__decade--neighboringCentury {
		color: #5c6368 !important;
	}

	.react-calendar__tile {
		max-width: 100%;
		width: 36px;
		height: 36px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 3px 9px;
		background: none;
		text-align: center;
		font: inherit;
		font-size: 15px;
		font-weight: 500;
		line-height: 140%;
		border-radius: 8px;
		color: #000b14;
	}

	.react-calendar__tile:disabled {
		background-color: #f0f0f0;
		color: #ababab;
	}

	.react-calendar__month-view__days__day--neighboringMonth:disabled,
	.react-calendar__decade-view__years__year--neighboringDecade:disabled,
	.react-calendar__century-view__decades__decade--neighboringCentury:disabled {
		color: #5c6368 !important;
	}

	.react-calendar__tile:enabled:hover,
	.react-calendar__tile:enabled:focus {
		background-color: #e6e6e6;
	}

	.react-calendar__tile--hasActive {
		background: #edf0f2;
	}

	.react-calendar__tile--hasActive:enabled:hover,
	.react-calendar__tile--hasActive:enabled:focus {
		background: #a9d4ff;
	}

	.react-calendar__tile--active {
		background: #edf0f2;
	}

	.react-calendar__tile--active:enabled:hover,
	.react-calendar__tile--active:enabled:focus {
		background: #1087ff;
	}

	.react-calendar--selectRange .react-calendar__tile--hover {
		background-color: #e6e6e6;
	}
}

.mini-text {
	display: inline-block;
    color: rgba(113, 113, 113, 0.6) !important;
    font-weight: 500 !important;
    margin-top: 0.75rem !important;
}
