.coverage {
	margin-top: 80px;
	padding: 30px 120px;
	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		h4 {
			color: $black;
			font-size: 24px;
			font-weight: 600;
			line-height: 29.05px;
			text-align: left;
		}

		&--filterContainer {
			position: relative;
			.filterList {
				position: absolute;
				z-index: 10001;
				top: 35px;
				right: 0px;
				width: 230px;
				padding: 16px;
				border-radius: 12px;

				background-color: #fff;
				border-width: 0px, 1px, 1px, 1px;

				border-style: solid;

				border-color: #f2f6f5;
				li {
					cursor: pointer;
					padding: 10px 7px;
					color: #5c6368;
					font-size: 14px;
					font-weight: 400;
					line-height: 20px;
					text-align: left;
					transition: all 0.5s;
					&:hover {
						background-color: #f5f5f5;
					}
				}
			}
		}
		&--filter {
			display: flex;
			align-items: center;
			min-width: 102px;
			width: 100%;
			height: 44px;
			justify-content: space-between;
			background-color: #f5f5f5;
			border: 1px solid #f2f6f5;
			padding: 0.875rem;
			border-radius: 6px;
			gap: 10px;
			transition: all 0.25s ease-in-out;
			&:hover,
			&:focus {
				background-color: darken($off-white, 3%);
			}
			cursor: pointer;
			> p {
				font-size: 14px;
				font-weight: 400;
				line-height: 17px;
				letter-spacing: 0em;
				text-align: left;
				color: $black;
				opacity: 0.8;
				text-transform: capitalize;
			}
		}
		.not-allowed {
			cursor: not-allowed;
		}
		&--buttons {
			display: flex;
			gap: 20px;
		}
		.state__select {
			position: relative;
			&--listContainer {
				position: absolute;
				top: 46px;
				left: 0px;
				height: 245px;
				background-color: #f5f5f5;
				z-index: 19;
				width: 100%;
				.list {
					height: 182px;
					overflow: scroll;
					padding: 12px 20px 6px;

					label {
						display: flex;
						justify-content: space-between;
						padding: 6.5px 0px;
						margin-bottom: 6px;

						font-weight: 400;
						font-size: 12px;
						line-height: 100%;
						letter-spacing: 0%;
						color: #344054;

						input {
							width: 16px;
							height: 16px;
							cursor: pointer;
						}
					}
				}
				.btns {
					display: flex;
					gap: 20px;

					padding: 8px 20px;

					.filterBtn {
						width: 100%;
						color: #3d0814;
					}
				}
			}
		}
		.state__filters {
			display: flex;
			width: 327px;

			border-radius: 8px;
			overflow: hidden;
			align-items: center;
			border: 1px solid #ffffff94;
			.location {
				border-radius: 0px;
				padding: 0 12px;
			}
			hr {
				border: none;
				border-right: 1px solid #d3d3d399;
				height: 32px;
			}
		}
	}
	&__body {
		border: 1px solid #f2f6f5;
		border-top-left-radius: 12px;
		border-top-right-radius: 12px;
	}
}
