.transaction {
	padding: 40px;

	@media #{ $mediumdown } {
		padding: 24px;
	}

	display: flex;
	flex-direction: column;
	gap: 30px;
	align-items: start;
	justify-content: start;

	.credit__container {
		display: flex;
		width: 100%;
		justify-content: start;
		align-items: start;
		flex-direction: row;
		border: 1px solid #d3d3d399;
		border-radius: 12px;
		padding: 16px 20px;
		gap: 16px;
		background-image: url('../../assets/svgs/transaction-header-bg.svg');

		&--card {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			gap: 16px;

			&--content {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 12px;

				&--header {
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 8px;

					svg {
						cursor: pointer;
					}

					h4 {
						color: #5c6368;
						font-size: 14px;
						font-style: normal;
						font-weight: 600;
						line-height: 16.94px;
					}
				}

				&--footer {
					font-weight: 600;
					font-size: 24px;
					color: #000b14;
					letter-spacing: 2%;
				}
			}
		}
	}

	.transaction__container {
		display: flex;
		flex-direction: column;
		gap: 20px;
		align-items: start;
		justify-content: start;
		width: 100%;

		.transaction__header {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			width: 100%;

			@media #{ $mediumdown } {
				flex-direction: column;
				align-items: end;
				justify-content: start;
				gap: 16px;
			}

			.outline {
				border: 1px solid rgba(211, 211, 211, 0.6);
				cursor: pointer !important;
			}

			.search-filter-container {
				display: flex;
				align-items: center;
				justify-content: end;
				gap: 20px;

				@media #{ $smalldown } {
					flex-direction: column-reverse;
					align-items: end;
					justify-content: start;
					gap: 16px;
				}

				.search {
					display: flex;
					align-items: center;
					width: 300px;
					height: 44px;
					border: 1px solid rgba(211, 211, 211, 0.6);
					border-radius: 8px;
					background: #f5f5f5;
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 6px;
					padding: 16px;
					cursor: pointer;

					// svg {
					// 	opacity: 0.4;
					// }
					@media #{ $xs-md-down } {
						width: 100%;
					}

					input {
						outline: none;
						border: none;

						&::placeholder {
							color: #5c6368;
						}

						font-weight: 400;
						font-size: 16px;
						line-height: 120%;
						color: $black;
						width: 95%;
						background: transparent;
					}
				}

				.filter-btn {
					display: flex;
					align-items: center;
					justify-content: center;
					min-width: 125px;
					height: 44px;
					border: 1px solid rgba(211, 211, 211, 0.6);
					border-radius: 8px;
					background: #f5f5f5;
					gap: 8px;
					padding: 16px;
					cursor: pointer;
					position: relative;

					span {
						color: #858585;
						font-size: 16px;
						font-style: normal;
						font-weight: 400;
					}

					&--dropdown {
						width: 226px;
						height: 180px;
						background: #ffffff;
						border: 1px solid rgba(211, 211, 211, 0.6);
						border-radius: 8px;
						position: absolute;
						top: 105%;
						right: 0;
						display: flex;
						flex-direction: column;
						align-items: start;
						justify-content: start;
						padding: 16px 0;
						z-index: 50;

						&--item {
							color: #858585;
							flex: 1;
							font-size: 16px;
							font-style: normal;
							font-weight: 400;
							cursor: pointer;
							width: 100%;
							padding: 0 16px;
							display: flex;
							justify-content: start;
							align-items: center;

							&:hover {
								background-color: #f5f5f5;
							}
						}
					}
				}
			}
		}

		.transaction__table {
			width: 100%;
			border-radius: 15px;
			border: 1px solid #f2f6f5;

			.noTransactions__container {
				width: 300px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				height: 100%;
				margin: auto;
				margin-top: 3rem;

				img {
					width: 250.06px;
					height: 120px;
					margin-bottom: 12px;
				}
				span {
					font-weight: 700;
					font-size: 18px;
					color: #344054;
					margin-bottom: 6px;
				}

				p {
					font-weight: 400;
					font-size: 14px;
					line-height: 160%;
					text-align: center;
					color: #475467;
					line-height: 150%;
					margin-bottom: 20px;
				}
			}
		}
	}
}
