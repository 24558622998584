.becomePartner {
	padding: 40px;
	padding-top: 121px;
	max-width: 1200px;
	width: 100%;
	margin: 0 auto;
	@media #{ $mediumdown } {
		padding: 20px;
	}
	&__header--headerText {
		color: $black;
		font-size: 32px;
		font-weight: 600;
		line-height: 35.2px;
		text-align: center;
		@media #{ $mediumdown } {
			font-size: 1.5rem;
		}
	}
	&__header--bodyText {
		color: #5c6368;
		font-size: 16px;
		font-weight: 400;
		line-height: 22.4px;
		text-align: center;
		margin-top: 8px;
		@media #{ $mediumdown } {
			font-size: 14px;
		}
	}
	&__body {
		padding-top: 50px;
		&--cards {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 60px;
			@media #{ $mediumdown } {
				grid-template-columns: repeat(1, 1fr);
				grid-gap: 20px;
			}
		}

		&--card {
			border: 1px solid #d3d3d399;
			padding: 30px;

			border-radius: 12px;
			.lightBlue {
				background-color: #e7faff;
			}
			.lightGreen {
				background-color: #e2ffed;
			}
			.purple {
				background-color: #e7e3ff;
			}

			.__header {
				display: flex;
				justify-content: center;
				margin-bottom: 20px;
				span {
					height: 110px;
					width: 110px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 50%;
				}
			}
			.__body {
				padding: 1rem;
				border-radius: 12px;
				&--text {
					color: $black;
					font-size: 20px;
					font-weight: 600;
					line-height: 30px;
					text-align: center;
				}
				&--miniText {
					margin-top: 16px;
					color: #5c6368;
					font-size: 14px;
					font-weight: 400;
					line-height: 19.6px;
					text-align: center;
				}
			}
		}
		.submitBtn {
			margin-top: 50px;
			@media #{ $mediumdown } {
				margin-top: 30px;
			}
		}
	}
}
.enterprise {
	padding: 40px;
	&__header {
		&--container {
			display: flex;
			gap: 24px;
		}
		&--btn {
			display: flex;
			justify-content: end;
			margin-bottom: 20px;
			.add {
				margin-right: 10px;
				font-size: 24px;
				margin-bottom: 4px;
			}
		}
		&--cards {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 24px;
		}
		&--text {
			font-weight: 500;
			font-size: 24px;
			line-height: 29.05px;
			letter-spacing: 0%;
			padding-bottom: 12px;
		}
		&--left {
			width: 65%;
			border: 1px solid #f5f5f5;
			padding: 22.5px 20px;
			border-radius: 12px;
		}
		&--right {
			padding: 22.5px 20px;
			border: 1px solid #f5f5f5;
			width: 35%;
			border-radius: 12px;
			.expiring__clients {
				display: flex;
				justify-content: space-between;
				&--left {
					span {
						display: flex;
						width: 118px;
						height: 122px;
						justify-content: center;
						align-items: center;
						border: 9px solid #005397;
						border-radius: 50%;
						margin-bottom: 16px;
					}
					p {
						color: #005397;
						font-weight: 600;
						font-size: 14px;
						line-height: 16.94px;
						letter-spacing: 2%;
						text-decoration: underline;
						cursor: pointer;
					}
				}
				&--right {
					.daysToExpiriy {
						display: flex;
						margin-bottom: 20px;
						gap: 8px;
						align-items: center;
						span {
							min-width: 84px;
							border-radius: 4px;
							padding: 4px 8px;
							font-weight: 500;
							font-size: 14px;
							line-height: 16.94px;
							letter-spacing: 0%;
						}
						p {
							font-weight: 600;
							font-size: 24px;
							line-height: 28px;
							letter-spacing: 2%;
						}
						.critical {
							color: #e0063c;
							background-color: #fff3f3;
						}
						.warning {
							color: #e68f00;
							background-color: #fffaf3;
						}
						.expired {
							color: #5f6062;
							background-color: #f5f5f5;
						}
					}
				}
			}
		}
	}
	&__body {
		margin-top: 60px;
		&--header {
			margin-bottom: 20px;
			ul {
				display: flex;
				gap: 8px;
				flex-wrap: wrap;
				li {
					font-style: normal;
					font-weight: 500;
					font-size: 0.87rem;
					line-height: 17px;
					color: #3d0814;
					background: #f5f5f5;
					border-radius: 100px;
					padding: 8px 24px;
					cursor: pointer;
					transition: all 0.5s;
					&:hover {
						background: darken($color: #f5f5f5, $amount: 9);
					}
				}
				.active {
					background: #005397;
					color: #ffffff;
					&:hover {
						background: lighten($color: #005397, $amount: 5);
					}
				}
			}
		}
		&--table {
			margin-top: 20px;
			border: 1px solid #f2f6f5;
			border-top-left-radius: 12px;
			border-top-right-radius: 12px;
		}
	}
}
.checkbox {
	> label {
		display: flex;
		align-items: center;
		font-size: 16px;
		font-weight: 500;
		line-height: 20px;
		letter-spacing: 0.10000000149011612px;
		text-align: left;
		color: #5c6368;
		margin-bottom: 12px;
		padding: 8px;
		gap: 8px;

		input {
			width: 1rem;
			height: 1rem;
			border: 1px solid #d3d3d399;
			border-radius: 4px;
			cursor: pointer;
		}
	}
}
.plan__card {
	// width: 355px;
	border: 1px solid #d3d3d399;
	border-radius: 10px;
	background-image: url('../../assets/svgs/Group.svg');
	background-repeat: no-repeat;
	background-position-x: right;

	&--header {
		display: flex;
		align-items: center;
		gap: 12px;
		padding: 17px;
		img {
			height: 32px;
			width: 32px;
		}
		.header__content {
			// width: 120px;
			// height: 20px;
			// overflow: hidden;
			h2 {
				font-style: normal;
				font-weight: 500;
				font-size: 0.88rem;
				line-height: 17px;
				color: $black;
				text-transform: capitalize;
			}
			p {
				font-style: normal;
				font-weight: 400;
				font-size: 0.75rem;
				line-height: 15px;
				color: $gray;
				span {
					font-weight: 500;
					color: #005397;
					text-decoration: underline;
					cursor: pointer;
				}
			}
		}
	}
	&--body {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 17px;
		p {
			font-style: normal;
			font-weight: 500;
			font-size: 0.75rem;
			line-height: 15px;
			color: $black;
		}
		.price {
			font-style: normal;
			font-weight: 600;
			font-size: 1.125rem;
			line-height: 22px;
			color: $black;

			span {
				font-weight: 500;
				font-size: 0.87rem;
				color: $gray;
			}
		}
	}
	&--bottom {
		display: flex;
		margin-top: 16px;
		// padding-top: 17px;
		align-items: center;
		background: #fff3f3;
		overflow: hidden;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		.plan__btn {
			width: 100%;
			background: #fff3f3;
			backdrop-filter: blur(15px);
			border: none;
			outline: none;
			height: 48px;
			font-style: normal;
			font-weight: 500;
			font-size: 0.875rem;
			line-height: 150%;
			transition: all 0.5s;
			overflow: hidden;
			color: #005397;

			&:hover {
				background-color: #ffeaea;
			}
		}
	}
	.plan_nameID {
		display: flex;
		justify-content: space-between;
		padding-right: 17px;
		> span {
			color: #005397;
			font-size: 14px;
			font-weight: 500;
			line-height: 16.94px;
			text-align: left;
			height: 25px;
			width: 25px;
			display: flex;
			justify-content: center;
			align-items: center;
			background: #f5fbff;
			border-radius: 50%;
		}
	}
}

.clients {
	margin-top: 80px;
	padding: 30px 120px;
	&__error {
		margin-bottom: 30px;
		padding: 24px;
		background: #fff3f3;
		border-radius: 12px;
		h4 {
			color: $black;
			font-size: 14px;
			font-weight: 600;
			line-height: 21px;
			text-align: left;
		}
		li {
			color: #5c6368;
			font-size: 14px;
			font-weight: 400;
			line-height: 21px;
			text-align: left;
			margin-top: 6px;
		}
		&--list {
			display: flex;
			justify-content: space-between;
			.formats {
				list-style: disc;
			}
		}
	}
	&__header {
		h4 {
			color: $black;
			font-size: 24px;
			font-weight: 600;
			line-height: 29.05px;
			text-align: left;
		}
		.mini-text {
			color: #5c6368;
			font-size: 14px;
			font-weight: 400;
			line-height: 19.6px;
			text-align: left;
			margin-top: 12px;
		}
		&--pills {
			max-width: 768px;
			width: 100%;
			margin: 0 auto;
			margin-top: 30px;
			display: flex;
			height: 48px;
			justify-content: space-between;
			background-color: #f5f5f5;
			border-radius: 40px;
			overflow: hidden;
			.active {
				background-color: #005397;
				border-radius: 40px;

				p {
					color: #ffffff;
				}
			}
		}
		&--pill {
			width: 100%;
			cursor: pointer;
			> p {
				text-transform: uppercase;
				text-align: center;
				font-size: 1rem;
				font-weight: 600;
				line-height: 19.36px;
				padding-top: 1rem;
				padding-bottom: 1rem;
				color: #3d0814;
				@media #{ $smalldown } {
					font-size: 0.875rem;
				}
			}
		}
		&--filterContainer {
			position: relative;
			.filterList {
				position: absolute;
				z-index: 10001;
				top: 35px;
				right: 0px;
				width: 230px;
				padding: 16px;
				border-radius: 12px;

				background-color: #fff;
				border-width: 0px, 1px, 1px, 1px;

				border-style: solid;

				border-color: #f2f6f5;
				li {
					cursor: pointer;
					padding: 10px 7px;
					color: #5c6368;
					font-size: 14px;
					font-weight: 400;
					line-height: 20px;
					text-align: left;
					transition: all 0.5s;
					&:hover {
						background-color: #f5f5f5;
					}
				}
			}
			.postitonLeft {
				left: 0;
			}
		}
		&--filter {
			display: flex;
			align-items: center;
			min-width: 102px;
			width: 100%;
			height: 40px;
			justify-content: space-between;
			background-color: #f5f5f5;
			border: 1px solid #f2f6f5;
			padding: 0.875rem;
			border-radius: 6px;
			gap: 10px;
			cursor: pointer;
			transition: all 0.25s ease-in-out;
			&:hover,
			&:focus {
				background-color: darken($off-white, 3%);
			}

			> p {
				font-size: 14px;
				font-weight: 400;
				line-height: 17px;
				letter-spacing: 0em;
				text-align: left;
				color: $black;
				opacity: 0.8;
				text-transform: capitalize;
			}
		}
		&--filtersbox {
			display: flex;
			align-items: center;
			gap: 20px;
			justify-content: flex-end;
		}
	}
	&__table {
		width: 100%;
		&--header {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		&--body {
			border: 1px solid #f2f6f5;
			border-top-left-radius: 12px;
			border-top-right-radius: 12px;

			&--no_records {
				border-radius: 12px;
				&--container {
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					padding: 20px;
					gap: 12px;

					img {
						width: 165px;
					}
					.text {
						width: 100%;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						gap: 4px;

						&--title {
							font-size: 16px;
							font-weight: 500;
							line-height: 19.36px;
							color: #000;
							text-align: center;
							letter-spacing: 0.32px;
						}
						&--subtitle {
							font-size: 14px;
							font-weight: 400;
							line-height: 21px;
							color: #475467;
							text-align: center;
						}
					}
				}
			}
		}
	}
	&__details {
		&--deets {
			display: flex;
			padding: 20px 0px;
			border-bottom: 1px solid #f5f5f5;
			.deet {
				width: 40%;
				p {
					color: #5c6368;
					font-size: 12px;
					font-weight: 500;
					line-height: 14.52px;
					text-align: left;
					display: flex;
					align-items: center;
					gap: 8px;
					margin-bottom: 6px;
				}
				h4 {
					color: $black;
					font-size: 16px;
					font-weight: 500;
					line-height: 19.36px;
					text-align: left;
				}
			}
		}
		&--pass {
			.pass__deets {
				padding: 20px;
				background-color: #f5fbff;
				margin: 20px 0;
				border-radius: 12px;
				display: flex;
				align-items: center;
				> .form-group {
					margin-right: 10px;
					margin-bottom: 0px;
				}
				.deets--side {
					width: 40%;
					h4 {
						color: #005397;
						font-size: 12px;
						font-weight: 500;
						line-height: 14.52px;
						text-align: left;
						text-transform: uppercase;
						display: flex;
						align-items: center;
						gap: 8px;
					}
					> p {
						padding-top: 6px;
						color: #005397;
						font-size: 16px;
						font-weight: 500;
						line-height: 19.36px;
						text-align: left;
					}
				}
				.deets--btn {
					.__filterContainer {
						position: relative;
						.--filter {
							background-color: #005397;
							border-radius: 50px;
							padding: 12px 24px;
							display: flex;
							display: flex;
							gap: 10px;
							align-items: center;
						}
					}
					p {
						color: #ffff;
						font-size: 14px;
						font-weight: 500;
						line-height: 16.94px;
						text-align: left;
						cursor: pointer;
					}

					.filterList {
						position: absolute;
						z-index: 10001;
						top: 35px;
						width: 230px;
						padding: 16px;
						border-radius: 12px;

						background-color: #fff;
						border-width: 0px, 1px, 1px, 1px;

						border-style: solid;

						border-color: #f2f6f5;
						li {
							cursor: pointer;
							padding: 10px 7px;
							color: #5c6368;
							font-size: 14px;
							font-weight: 400;
							line-height: 20px;
							text-align: left;
							transition: all 0.5s;
							&:hover {
								background-color: #f5f5f5;
							}
						}
					}
				}
				.deets--date {
					background-color: #005397;
					padding: 12px 24px;
					color: #fff;
					border-radius: 50px;
					cursor: pointer;
					font-size: 14px;
					font-weight: 500;
					line-height: 16.94px;
					text-align: center;
				}
			}
			hr {
				border: none;
				border-bottom: 1px solid #f5f5f5;
			}
		}
	}
	.header--btn {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	&__body {
		max-width: 768px;
		width: 100%;
		margin: 0 auto;
		margin-top: 60px;
	}
	&__footer {
		height: 80px;
		position: fixed;
		bottom: 0px;
		left: 0px;
		width: 100%;
		background-color: #e7f5ff;
		&--container {
			max-width: 90%;
			width: 100%;
			height: 100%;
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: space-between;
			@media #{ $mediumdown } {
				max-width: 100%;
				padding: 0 1rem;
			}
		}
		.__left {
			display: flex;
			gap: 1rem;
			@media #{ $mediumdown } {
				display: none;
			}

			.selected {
				display: flex;
				gap: 6px;
				align-items: center;
				font-family: Inter;
				font-size: 16px;
				font-weight: 500;
				line-height: 19.36px;
				color: #5c6368;
				> span {
					background-color: #005397;
					width: 40px;
					height: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 100px;
					font-size: 10px;
					font-weight: 500;
					line-height: 12.1px;
					color: #fff;
				}
			}
			.deselect {
				font-size: 16px;
				font-weight: 500;
				line-height: 19.36px;
				color: #5c6368;
				background: #f5f5f5;
				padding: 12px 24px;
				border-radius: 100px;
				cursor: pointer;
			}
		}
		.__right {
			font-size: 16px;
			font-weight: 500;
			line-height: 19.36px;
			color: #000b14;
			font-family: Inter;
			@media #{ $mediumdown } {
				width: 100%;
				font-size: 14px;
				font-weight: 500;
				line-height: 16.94px;
				text-align: left;
			}

			span {
				background-color: #005397;
				color: #fff;
				padding: 12px 24px;
				margin-left: 12px;
				border-radius: 40px;
				cursor: pointer;
				@media #{ $mediumdown } {
					padding: 6px 12px;
				}
			}
		}
	}
}
.addClients {
	&--header {
		&__info {
			background-color: #fff;
			padding: 25px 30px;
			box-shadow: 0px 1px 5px 0px #00000008;

			.add--image {
				display: inline-block;
				position: relative;
				height: 224px;
				border: 1px dashed #005397;
				padding: 16px 18.5px;
				display: flex;
				align-items: center;
				flex-direction: column;
				border-radius: 8px;
				justify-content: center;
				background-color: #f5fbff;
				.up-success {
					font-size: 20px;
					font-weight: 600;
					line-height: 24px;
					text-align: left;
					color: #344054;
					margin-top: 12px;
					margin-bottom: 20px;
					@media #{ $smalldown } {
						font-size: 1rem;
						margin-top: 0px;
						margin-bottom: 10px;
					}
				}
				.changeFile {
					display: flex;
					justify-content: space-between;
					width: 95%;
					border-radius: 12px;
					margin: 0 auto;
					background-color: #fff;
					border: 1px solid #d3d3d399;
					padding: 16px 20px;
					@media #{ $largedown } {
						background-color: transparent;
						border: none;
						flex-direction: column;
						align-items: center;
					}
					.file--deets {
						display: flex;
						align-items: center;
						gap: 16px;
						&__name {
							font-size: 14px;
							font-weight: 700;
							line-height: 16.94px;
							text-align: left;
							color: #5c6368;
							span {
								font-weight: 400;
								@media #{ $smalldown } {
									display: none;
								}
							}
						}
						&__size {
							font-size: 14px;
							font-weight: 400;
							line-height: 16.94px;
							text-align: left;
							color: #5c6368;
							list-style: #5c6368;
						}
					}
					> .action--btn {
						display: flex;
						gap: 16px;
						@media #{ $smalldown } {
							margin-top: 16px;
						}
						> .upload__btn {
							color: $f-blue;
							font-size: 1rem;
							font-weight: 600;
							line-height: 24px;
							text-align: left;
							margin-top: 0px;
							text-decoration: underline;
							cursor: pointer;
							position: relative;
							@media #{ $smalldown } {
								font-size: 12px;
								font-weight: 600;
								line-height: 16.94px;
								text-align: left;
							}
							> input {
								position: absolute;
								left: 0;
								top: 0;
								width: 100%;
								height: 100%;
								opacity: 0;
								cursor: pointer;
								box-sizing: border-box;
							}
						}
						> a {
							color: $f-blue;
							font-size: 1rem;
							font-weight: 600;
							line-height: 24px;
							text-align: left;
							margin-top: 0px;
							text-decoration: underline;
							cursor: pointer;
							@media #{ $smalldown } {
								font-size: 12px;
								font-weight: 600;
								line-height: 16.94px;
								text-align: left;
							}
						}
					}
				}
				.image--text {
					> p {
						font-size: 14px;
						font-weight: 500;
						line-height: 16px;
						color: $f-blue;
						text-align: center;
						margin-top: 12px;
						margin-bottom: 6px;
						padding: 0;
						> span {
							text-decoration: underline;
						}
					}
				}
				> span {
					color: #475467;
					font-size: 14px;
					font-weight: 400;
					line-height: 16.94px;
					text-align: center;
				}

				.file-input {
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					opacity: 0;
					cursor: pointer;
					box-sizing: border-box;
					&::-webkit-file-picker-indicator {
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						margin: 0;
						padding: 0;
						cursor: pointer;
					}
				}
			}
			.csv--type {
				font-size: 20px;
				font-weight: 500;
				line-height: 24.2px;
				text-align: left;
				color: #5c6368;
				@media #{ $smalldown } {
					font-size: 10px;
					font-weight: 500;
					line-height: 12.1px;
					text-align: left;
				}
			}
			.template {
				display: flex;
				width: 100%;
				justify-content: space-between;
				border: 1px solid #d3d3d399;
				background-color: #f5f5f5;
				padding: 20px 30px;
				border-radius: 12px;
				margin-top: 30px;
				&--text {
					> h3 {
						color: #344054;
						font-size: 20px;
						font-weight: 600;
						line-height: 24px;
						text-align: left;
						display: flex;
						gap: 12px;
						align-items: center;
						@media #{ $smalldown } {
							font-size: 14px;

							line-height: 16.94px;
							text-align: left;
						}
					}
					> p {
						color: #5c6368;
						font-size: 14px;
						font-weight: 400;
						line-height: 16.94px;
						text-align: left;
						margin-top: 12px;
						@media #{ $smalldown } {
							font-size: 12px;
							font-weight: 400;
							line-height: 14.52px;
							text-align: left;
						}
					}
				}
				&--btn {
					> a {
						button {
							display: flex;
							gap: 10px;
							outline: none;
							align-items: center;
							border: 1px solid #005397;
							background-color: white;
							padding: 18px 24px;
							border-radius: 8px;
							color: $f-blue;
							font-size: 1rem;
							font-weight: 600;
							line-height: 19.36px;
							letter-spacing: 0.02em;
							text-align: center;
							transition: all 0.5s;
							&:hover,
							&:focus {
								background-color: darken(#ffffff, 3%);
							}
							span {
								@media #{ $mediumdown } {
									display: none;
								}
							}
						}
					}
				}
			}
		}
		&__deets {
			background-color: transparent;
			border: 1px solid $stroke-alt;
			padding: 20px 30px;
			border-top-left-radius: 12px;
			border-top-right-radius: 12px;

			h1 {
				color: #011627;
				font-size: 1.5rem;
				font-weight: 600;
				line-height: 29.05px;
				text-align: left;
				@media #{ $smalldown } {
					font-size: 14px;
					font-weight: 600;
					line-height: 16.94px;
					text-align: left;
				}
			}
			p {
				color: #5c6368;
				font-size: 16px;
				font-weight: 400;
				line-height: 19.36px;
				letter-spacing: 0.01em;
				text-align: left;
				margin-top: 12px;
				@media #{ $smalldown } {
					font-size: 12px;
					font-weight: 400;
					line-height: 14.52px;
				}
			}
		}
	}
}

.slick-slide {
	padding: 0 7px;
}

.error__table {
	border: 1px solid #f2f6f5;
	overflow: hidden;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	> table {
		tr {
			th {
				background-color: #f3f3f3;
			}
		}
	}

	> p {
		background-color: #e0063c;
		font-family: Inter;
		font-size: 16px;
		font-weight: 700;
		line-height: 24px;
		text-align: center;
		color: #fff;
		padding: 11px 0px;
		text-transform: uppercase;
	}
	.errText {
		border: 1px solid #e0063c;
		text-transform: capitalize;
		padding: 8px 4px;
	}
	.warningText {
		border: 1px solid #e98c00;
		text-transform: capitalize;
		padding: 8px 4px;
	}

	.error-table {
		font-weight: 400;
		font-size: 12px;
		line-height: 150%;
		letter-spacing: 0%;
		vertical-align: middle;
		color: #5c6368;
	}
}

.emptyClients {
	padding-top: 30px;
}
