.dashboard {
	padding: 40px;
	&__title {
		font-weight: 500;
		font-size: 24px;
		line-height: 29.05px;
		letter-spacing: 0%;
		color: #000;
	}
	&__textLink {
		color: #005397;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: 2%;
		text-decoration: underline;
		cursor: pointer;
		transition: all 0.25s ease-in-out;
		&:hover,
		&:focus {
			color: darken(#005397, 8%);
		}
	}
	&__header {
		&--container {
			border: 1px solid #b8e1ff;
			background-color: #f5fbff;
			display: flex;
			padding: 20px;
			border-radius: 12px;
			justify-content: space-between;
			align-items: center;
			margin-top: 12px;
			overflow: hidden;
			.iconCircle {
				> span {
					display: flex;
					width: 59px;
					height: 63px;
					justify-content: center;
					align-items: center;
					border: 6px solid #005397;
					border-radius: 50%;
				}

				width: 8%;
			}
			> p {
				width: 10%;
			}
		}
		&--cards {
			display: flex;
			justify-content: space-between;
			width: 82%;
			.card {
				width: 33.3%;
				padding: 0px 40px;
				border: 0px;
				border-right: 1px solid #b8e1ff;

				&:last-child {
					border-right: 0px;
				}
				&:first-child {
					padding-left: 0px;
				}
				.daysToExpiriy {
					display: flex;
					flex-direction: column;
					gap: 8px;
					span {
						width: 100px;
						border-radius: 4px;
						padding: 4px 8px;
						font-weight: 500;
						font-size: 16px;
						line-height: 19.94px;
						letter-spacing: 0%;
					}
					p {
						font-weight: 600;
						font-size: 24px;
						line-height: 28px;
						letter-spacing: 2%;
					}
					.critical {
						color: #e0063c;
						background-color: #fff3f3;
					}
					.warning {
						color: #e68f00;
						background-color: #fffaf3;
					}
					.expired {
						color: #5f6062;
						background-color: #f5f5f5;
					}
				}
			}
		}
	}
	&__body {
		margin-top: 30px;
		&--header {
			display: flex;
			justify-content: space-between;
			margin-bottom: 12px;
		}
		&--cards {
			display: grid;
			grid-template-columns: repeat(2, 1fr);

			gap: 36px;
		}
		&--card {
			border: 1px solid #d3d3d399;
			overflow: hidden;
			border-radius: 12px;
			.card--header {
				display: flex;
				gap: 12px;
				padding: 16px 20px;
				border-bottom: 1px solid #d3d3d399;
				.header__content {
					h2 {
						font-weight: 600;
						font-size: 16px;
						line-height: 19.36px;
						letter-spacing: 0px;
						color: $black;
					}
					p {
						font-weight: 400;
						font-size: 12px;
						line-height: 14.52px;
						letter-spacing: 0px;
						color: #5c6368;
					}
				}
			}
			.card--body {
				padding: 16px 20px;

				.card--deets {
					display: flex;
					width: 100%;
					margin-bottom: 20px;
					&:last-child {
						.card--deet {
							width: 100%;
						}
					}
					.card--deet {
						width: 50%;

						p {
							font-weight: 500;
							font-size: 12px;
							line-height: 14.52px;
							letter-spacing: 2%;
							color: #5c6368;
						}
						.subText {
							font-size: 14px;
							line-height: 16.94px;
							letter-spacing: 0%;
							color: $black;
							margin-top: 6px;
						}
					}
					.tier {
						display: flex;
						gap: 12px;
						margin-top: 12px;

						&--info {
							background-color: #f5f5f5;
							padding: 6px 10px;
							border-radius: 4px;
							p {
								font-weight: 500;
								font-size: 12px;
								line-height: 18px;
								letter-spacing: 0%;
							}
							.subText {
								font-size: 12px;
							}
						}
					}
				}
			}
		}
	}
}
