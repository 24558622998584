//Header
//author: @Anisat Yunusa

.layout {
	display: flex;
	width: 100%;

	&__main {
		width: 84%;
		overflow-x: hidden;
		overflow-y: scroll;
		@media #{ $largedown } {
			width: 100%;
		}

		.container {
			margin-top: 80px;
		}

		// &--section {
		// 	margin-top: 4rem;
		// 	&:first-child {
		// 		margin-top: 0;
		// 	}
		// }
	}
}
