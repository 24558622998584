.pagination {
    padding: 16px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #F2F6F5;
    .pagecontrols {
        display: flex;
        gap: 10px;
        align-items: center;
        .showmore--container {
            margin-top: 0px;
            cursor: pointer;
            .showOptions {
                position: absolute;
                bottom: 50px;
                z-index: 1px;
                right: 0px;

                .showOption--numbers {
                    height: 164px;
                    width: 70px;
                    padding: 16px 0;
                    overflow: scroll;
                    border: 1px solid #d3d3d399;
                    background-color: inherit;
                    background-color: #f5f5f5;
                    border-radius: 8px;
                    .numbers {
                        cursor: pointer;
                        text-align: center;
                        padding: 2px 10px;
                        margin: 0 auto;
                        margin-bottom: 12px;
                        background-color: transparent;
                        width: fit-content;
                        border-radius: 4px;
                        transition: all 0.3s;
                        color: #5c6368;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        letter-spacing: 0%;

                        &:hover {
                            background-color: #d3d3d399;
                        }
                    }
                    .active {
                        background-color: #d3d3d399;
                    }
                }
            }
        }
        .container {
            border: 1px solid #d3d3d399;
            border-radius: 10px;
            padding: 15px;
            background-color: #f5f5f5;
            display: flex;
            position: relative;
            margin-top: 0px;
            svg {
                margin-left: 5px;
                transform: rotate(270deg);
            }
        }
    }
    .container {
        border: 1px solid #d3d3d399;
        border-radius: 10px;
        padding: 15px;
        background-color: #f5f5f5;
        display: flex;
        position: relative;
        transition: all 0.3s;

        svg {
            margin-left: 5px;
        }
        &:hover,
        &:focus {
            background-color: darken($off-white, 3%);
        }
    }
    @media #{ $mediumdown } {
        justify-content: center;
    }
    p {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 17px;
        letter-spacing: 0.004em;
        text-transform: uppercase;
        color: $gray;

        @media #{ $mediumdown } {
            display: none;
        }
    }
    .pages {
        display: flex;
        gap: 24px;
        align-items: center;

        .prev {
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: pointer;
            margin-top: 0px;
            span {
                font-weight: 400;
                font-size: 0.875rem;
                line-height: 17px;
                letter-spacing: 0.004em;
                text-transform: uppercase;
                color: $gray;
            }
        }
        .next {
            display: flex;
            align-items: center;
            gap: 8px;

            cursor: pointer;
            margin-top: 0px;
            span {
                font-weight: 400;
                font-size: 0.875rem;
                line-height: 17px;
                letter-spacing: 0.004em;
                text-transform: uppercase;
                color: $black;
            }
            .prevSvg {
                transform: rotate(180deg);
            }
        }
        .numbers {
            display: flex;
            gap: 4px;
            span {
                width: 24px;
                height: 24px;

                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;

                font-weight: 500;
                font-size: 0.875rem;
                line-height: 17px;

                letter-spacing: 0.004em;
                color: #3d0814;
                cursor: pointer;
                &:hover {
                    background-color: #f5f5f5;
                }
            }
            .active {
                background: #ff8788;
                &:hover {
                    background: lighten(#ff8788, 20%);
                    background-color: lighten($color: #ff8788, $amount: 5);
                    // background: lighten(#ff8788, 20%);
                }
            }
        }
    }
}