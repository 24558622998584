.employee {
	padding: 40px;
	@media #{ $mediumdown } {
		padding: 24px;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		@media #{ $mediumdown } {
			flex-direction: column-reverse;
			gap: 16px;
		}
		&--filterContainer {
			position: relative;
		}
		.search {
			display: flex;
			gap: 1rem;
		}
		&--container {
			display: flex;
			gap: 1.5rem;
			.filterList {
				position: absolute;
				height: fit-content;
				width: 220px;
				background-color: #fffcfc;
				// padding-top: 1.5rem;
				z-index: 1;
				box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px 0px;
				border-radius: 10px;

				li {
					// margin-bottom: 1.5rem;
					cursor: pointer;
					padding: 0.7rem 0 0.7rem 1.5rem;
					font-size: 14px;
					font-weight: 500;
					line-height: 24px;
					letter-spacing: 0.004em;
					text-align: left;
					color: #5c6368;
					&:hover {
						background: $off-white;
					}
					text-transform: capitalize;
				}
			}
		}
		.flipSides {
			flex-direction: row-reverse;
		}
		&--filter {
			display: flex;
			align-items: center;
			min-width: 102px;
			width: 100%;
			height: 40px;
			justify-content: space-between;
			border: 1px solid #f5f5f5;
			padding: 0.875rem;
			border-radius: 6px;
			gap: 10px;
			cursor: pointer;
			> p {
				font-size: 14px;
				font-weight: 400;
				line-height: 17px;
				letter-spacing: 0em;
				text-align: left;
				color: $black;
				opacity: 0.8;
				text-transform: capitalize;
			}
		}
		&--search {
			display: flex;
			align-items: center;
			gap: 8px;
			width: 404px;
			height: 40px;
			background: #ffffff;
			border: 1px solid #d3d3d3;
			border-radius: $preferredradius;
			padding: 12px;
			opacity: 0.8;
			// svg {
			// 	opacity: 0.4;
			// }
			@media #{ $xs-md-down } {
				width: 100%;
			}
			input {
				outline: none;
				border: none;
				// &::placeholder {
				// 	opacity: 0.4;
				// }
				//
				font-weight: 400;
				font-size: 1rem;
				line-height: 19px;
				color: $black;
				width: 95%;
			}
		}
		&--btn {
			width: 176px;
			height: 40px;
		}
	}
	&__details {
		&--header {
			> h2 {
				font-weight: 600;
				font-size: 1.2rem;
			}
			.info {
				display: flex;
				align-items: center;
				gap: 1.5rem;
				@media #{ $mediumdown } {
					flex-direction: column;
					align-items: flex-start;
				}
				.name__details {
					margin-top: 10px;
					text-transform: capitalize;
					.name {
						color: $black;
						font-weight: 500;
						font-size: 1rem;
					}
					.email {
						font-size: 12px;
						font-weight: 400;
						line-height: 15px;
						letter-spacing: 0px;
						margin-top: 5px;
					}
					.planName {
						font-size: 14px;
						font-weight: 500;
						color: #005397;
						margin-top: 12px;
					}
				}
				.initials__container {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 96px;
					height: 96px;
					background: #fffcfc;
					border: 1px solid #ff8788;
					color: #3d0814;
					border-radius: 50%;
					font-weight: 500;
					font-size: 1.875rem;
					line-height: 36px;
					color: #3d0814;
					margin-top: 18px;
					text-transform: uppercase;
				}
			}
		}
		&--body {
			max-width: 663px;
			border-radius: 10px;
			border: 1px dashed #f5f5f5;
			margin-top: 22px;
			padding: 2rem;
			@media #{ $mediumdown } {
				padding: 1rem;
			}
			> h2 {
				font-size: 0.875rem;
				font-weight: 600;
				line-height: 17px;
				color: #005397;
				margin-bottom: 1rem;
			}
			.__overview {
				display: flex;
				align-items: center;
				border-bottom: 1px dashed #f5f5f5;
				justify-content: space-between;
				padding: 1rem 0rem;
				max-width: 700px;

				> p {
					font-size: 0.875rem;
					font-weight: 600;
					line-height: 15px;
					letter-spacing: 0em;
					color: #5c6368;
				}
				> .benefits {
					gap: 1rem;
					display: flex;
					flex-wrap: wrap;
					// background-color: #005397;
					justify-content: flex-end;
					> span {
						display: flex;
						align-items: center;
						font-size: 0.875rem;
						font-weight: 600;
						line-height: 15px;
						letter-spacing: 0em;
						color: #5c6368;
						gap: 8px;
						@media #{ $mediumdown } {
							span {
								display: none;
							}
						}
					}
				}
			}
		}
	}
	&__table {
		margin-top: 32px;
		// border: 1px solid #f5f5f5;
		// border-radius: 15px;
		background-color: #fff;
		border: 1px solid $off-white;
		border-radius: 15px;
		// cursor: pointer;
	}
	.employee-success {
		margin-top: 30px;
		display: flex;
		flex-direction: column;
		align-items: center;
		// div {
		// 	display: flex;
		//
		// 	align-items: center;
		// 	justify-content: center;
		// 	width: 64px;
		// 	height: 64px;
		// }
		span {
			margin-bottom: 32px;
			svg {
				background-color: $primary-light-1;
				padding: 20px 16px;
				border-radius: 50%;
			}
		}
		h2 {
			font-style: normal;
			font-weight: 500;
			font-size: 1.25rem;
			line-height: 24px;
			color: $black;
			margin-bottom: 16px;
		}
	}
}

.employee__table--dropdown {
	position: absolute;
	z-index: 10;
	right: 0;
	top: 38px;
	padding: 0.5rem 0;
	min-width: 180px;
	background-color: #fff;
	border-radius: 10px;
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px 0px;
	box-sizing: border-box;

	li {
		width: 100%;
		border-radius: 0;
		font-size: 0.875rem;
		font-weight: 500;
		line-height: 24px;
		letter-spacing: 0.004em;
		color: $gray;
		display: flex;

		&:hover {
			background: $off-white;
		}

		a,
		span {
			width: 100%;
			padding: 0.5rem 1.5rem;
			color: inherit;
		}

		.logoutBtn {
			color: $danger;
		}
	}
}
